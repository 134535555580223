<template>
  <v-container
    v-bind="binding"
    fluid
    grid-list-xl
  >
    <breadcrumbs
      v-if="breadcrumbs"
      :breadcrumbs="breadcrumbs"
    />
    <h3
      v-if="title"
      class="headline grey--text text-xs-center hidden-sm-and-down"
    >
      {{ title }}
    </h3>
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        md8
        offset-md2
      >
        <v-stepper
          v-model="step"
          vertical
        >
          <template v-for="(thisFormStep, key, n) in formSteps">
            <v-stepper-step
              :key="key + '-subject'"
              :step="n + 1"
              :complete="step > n + 1"
            >
              {{ thisFormStep.title }}
              <small v-if="thisFormStep.subTitle">{{ thisFormStep.subTitle }}</small>
            </v-stepper-step>
            <v-stepper-content
              :key="key + '-content'"
              :step="n + 1"
            >
              <form-step
                :form-step="thisFormStep"
                :form-index="n"
                :step.sync="step"
                :submit.sync="thisFormStep.submit"
              />
            </v-stepper-content>
          </template>
        </v-stepper>
      </v-flex>
      <v-flex
        v-if="status === 'success' || status === 'info'"
        xs12
        md8
        offset-md2
      >
        <v-alert
          :color="color"
          :icon="icon"
          :value="true"
        >
          {{ alertText }}
          <v-btn
            v-if="status === 'info'"
            flat
          >
            Check Status
          </v-btn>
        </v-alert>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  components: {
    FormStep: () => import(/* webpackChunkName: "formStep" */ '@/components/FormStep'),
    Breadcrumbs: () => import(/* webpackChunkName: "breadcrumbs" */ '@/components/Breadcrumbs'),
  },
  props: {
    alertText: {
      type: String,
      default: null,
    },
    breadcrumbs: {
      type: Array,
      default: null,
    },
    formSteps: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      step: 1,
    }
  },
  computed: {
    binding: function () {
      return this.$vuetify.breakpoint.xsOnly ? { class: 'mx-0 px-0' } : {}
    },
    color: function () {
      if (this.status === 'success') return 'success'
      if (this.status === 'info') return 'info'
      return 'error'
    },
    formStep: function () {
      let index = 0
      for (const stepKey of Object.keys(this.formSteps)) {
        if (++index === this.step) return this.formSteps[stepKey]
      }
      return this.formSteps[0]
    },
    icon: function () {
      if (this.status === 'success') return 'check_circle'
      if (this.status === 'info') return 'info'
      return 'error'
    },
  },
}
</script>
